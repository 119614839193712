import { useEffect } from "react";
import { PageControl, KIND } from "baseui/page-control";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { useNavigate } from 'react-router-dom';
import { Analytics,Gtag } from '../../compornents/Analytics';
import React from 'react';

export const OrderStep1 = () => {
  const navigate = useNavigate();
  const engine = new Styletron();


  const next = () => {
    navigate('/order_step/step2');
  }

  useEffect(() => {
    Gtag('view_order_step1');
  }, []);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
        <Analytics title="OrderStep1" />
        <div className="Register">
          <div className="App-step1">
            <div className="App-h1 pt-5">{"STEP.1"}</div>
            <img src="/img/step1.png" alt="step1" className="App-img" />
            <div className="App-title">【初回限定】<span className="text-danger">3点990円</span>を<br />注文する</div>
            <div className="App-comment">スマホから注文を行い<br />クリーニングしたい衣類を専用バッグに入れる</div>
            <div className="App-h3">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <PageControl
                  currentPage={2}
                  numPages={6}
                  kind={KIND.default}
                  aria-label="label"
                />
              </div>
              <button className="button-next-w" onClick={next}>{"次へ"}</button>
            </div>
          </div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
