import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { ToastContainer, toast } from 'react-toastify';
import { Post } from '../compornents/Post';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { Analytics } from '../compornents/Analytics';

import 'react-toastify/dist/ReactToastify.css';

export const Other = () => {
  const navigate = useNavigate();
  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  // メニュー選択
  const selectMenu = (menu) => {
    console.log(menu);
    if(menu==='company'){
      navigate("/" + menu);
    } else if (menu === 'privacy') {
      navigate("/" + menu);
    } else if (menu === 'rule') {
      navigate("/" + menu);
    }else if(menu==='tokutei'){
      navigate("/" + menu);
    } else if (menu === 'baisyo') {
      navigate("/" + menu);
    }else if(menu==='logout'){
      // 通知
      toast("ログアウトしました");
      logout();
    } else if (menu === 'leave') {
      navigate("/" + menu);
    }
    // navigate("/order/" + menu);
  };
  async function logout() {
    openLoadingModal();
    const request_data = {}
    await Post('logout', '', request_data);
    setTimeout(() => { navigate("/register") }, 1500);
    closeLoadingModal();
  }
  // 戻る
  const back = () => {
    navigate("/account");
  };
  return (
    <div className="App">
      <div className="Other">
        <Analytics title="その他" />
        <div className="mb-5">
          <div className="toolbar mb-3">
            <div className="toolbar_left div_btn" onClick={back} >
              <BsChevronLeft />
            </div>
            <div className="toolbar_center">
              その他
            </div>
            <div className="toolbar_right">
            </div>
          </div>
          <div className="other_list">
            <div className="other div_btn" onClick={() => selectMenu('company')}>
              <div className="other_content">
                運営会社
              </div>
              <div className="other_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="other div_btn" onClick={() => selectMenu('privacy')}>
              <div className="other_content">
                プライバシーポリシー
              </div>
              <div className="other_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="other div_btn" onClick={() => selectMenu('rule')}>
              <div className="other_content">
                利用規約
              </div>
              <div className="other_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="other div_btn" onClick={() => selectMenu('tokutei')}>
              <div className="other_content">
                特定商取引法
              </div>
              <div className="other_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="other div_btn" onClick={() => selectMenu('baisyo')}>
              <div className="other_content">
                賠償基準について
              </div>
              <div className="other_arrow">
                <IoIosArrowForward />
              </div>
            </div>
            <div className="other div_btn" onClick={() => selectMenu('logout')}>
              <div className="other_content">
                ログアウト
              </div>
              <div className="other_arrow">
                <IoIosArrowForward />
              </div>

              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="dark"
              />
            </div>
            <div className="other div_btn" onClick={() => selectMenu('leave')}>
              <div className="other_content">
                退会
              </div>
              <div className="other_arrow">
                <IoIosArrowForward />
              </div>
            </div>
          </div>
        </div>
        <LoadingModal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeLoadingModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </LoadingModal>
        <Tabbar menu="account" />
      </div>

    </div>
  );
}
