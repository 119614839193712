import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Analytics } from '../compornents/Analytics';

export const Privacy = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Privacy">
        <Analytics title="プライバシーポリシー" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            プライバシーポリシー
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div>LAUNDRY PACK Now（以下、「弊社」といいます。）は、個人情報保護の重要性を認識し、以下に掲げる方針に基づき、また、個人情報保護に関連する法令その他の規範を遵守し、個人情報の保護に誠実に取り組んで参ります。</div>
          <div className="h5 mb-2 mt-4">１．個人情報の取得について</div>
          <div>弊社は、お客様に関する個人情報を、偽りその他不正な手段によることなく、適正に取得します。</div>
          <div className="h5 mb-2 mt-4">２．個人情報の利用について</div>
          <div>弊社は、保有する個人情報を、次に掲げる利用目的その他取得の際に示した利用目的の範囲内で、業務の遂行上必要な限りにおいて利用します。</div>
          <div className="mt20">(1) 情報提供等を送付するため<br />
            (2) アンケートの実施等による協会の商品やサービスの研究・開発のため<br />
            (3) その他お客様との契約や法律等に基づく権利の行使や義務の履行、取引を適切かつ円滑に履行するため</div>
          <div className="h5 mb-2 mt-4">３．安全対策について</div>
          <div>弊社は、個人情報の取得及び利用に際し、次の安全対策を実施します。</div>
          <div className="mt20">(1) 弊社は、保有する個人情報について、紛失、破壊、改ざん、漏洩又は不正アクセスの防止その他安全な管理のために、合理的かつ適切な措置を講じます。<br />
            (2)   弊社は、役員及び職員（以下、嘱託職員、臨時職員、派遣職員、アルバイト及びそれぞれについて雇用契約が終了した者を含みます。以下「役職員等」といいま  す。）に対し、個人情報の保護及び適正な管理方法についての研修等を実施し、日常業務における個人情報の適正な取扱いを徹底します。<br />
            (3) 弊社は、個人情報保護に関する規程を整備し、それを遵守します。</div>
          <div className="h5 mb-2 mt-4">４．個人情報の開示、訂正及び利用停止</div>
          <div>弊社が保有している保有個人データ（個人情報の保護に関する法律（平成１５年５月３０日法律第５７号）第５項に規定する保有個人データをいいます。）につ  いて、開示、訂正等及び利用停止等の請求があった場合は、請求者がお客様本人又は法定代理人（本人が未成年者若しくは成年被後見人である場合）若しくは本  人が委任した代理人であることを確認した上で、特別な理由のない限り速やかに対応します。</div>
          <div className="h5 mb-2 mt-4">５．個人情報の開示手続及びお問い合わせ窓口</div>
          <div>保有個人データの開示手続及びお問い合わせに関しては、弊社の運営事務局（info[a]laundry-pack.com（[at]の部分を@に置き換えてください。））が担当します。なお、保有個人データの開示等の文書は、本人の住所に書留郵便で送付します。</div>
          <div className="mt20">弊社が保有する保有個人データの開示のご請求を希望される場合には、上記の総務課宛にお問い合わせ下さい。なお、上記開示手続には、１請求当たり５００円の手数料が発生しますので、予めご了承下さい。</div>
          <div className="mt20 alignRight">制定日 : 2015年04月1日</div>
        </div>
      </div>

    </div>
  );
}
