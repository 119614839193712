import { useNavigate } from "react-router-dom";
import { Analytics } from '../compornents/Analytics';

import 'swiper/css';
import 'swiper/css/pagination'; // スタイルをインポート

export const Prepare = () => {
  // 初期化
  const navigate = useNavigate();

  // 戻る
  const toHome = () => {
    navigate("/home");
  };
  return (
    <div className="App">
      <div className="Prepare">
        <Analytics title="袋の準備" />
        <div className="mt-3 mb-5">
          <div className="mt-5 mb-4">
            <div className="h4 text-center">必要な情報を記入し<br />
              袋を密封してください</div>
          </div>
          <div>


            <div className="row">

              <div className="col-6">
                <img src="./img/Group 1424.png" alt="step1" className="ItemImage" />
              </div>
              <div className="col-6">
                <img src="./img/Group 1423.png" alt="step1" className="ItemImage" />
              </div>
            </div>

            <div className="text-right mb-4">
              <small>裏にしてもOKです！</small>
            </div>
            <div className="mb-4">
              ①②どちらかの方法で、携帯番号の下4桁とフルネームを記入し、Uber配達員に袋をお渡しください。
            </div>
            <div className="mb-4">
              <div className="line-height-sm"><small>※袋はこちらで処分させていただきます</small></div>
              <div className="line-height-sm"><small>※密封されていない場合、サービスをお受けできない場合があります。</small></div>
            </div>
            <div className="mb-3">
              <button className="btn btn-oval btn-outline-secondary form-control" onClick={toHome}>ホームに戻る</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
