import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Gtag } from '../compornents/Analytics';
import React from 'react';

export const RegisterMenu = () => {
  const navigate = useNavigate();


  const next = () => {
    navigate('/register');
  }

  useEffect(() => {
    Gtag('view_register_menu');
  }, []);

  return (
    <div className="Register m-auto">
      <div className="App-telno">
        <div className="h5 mt-4 mb-4 text-center">
          <div className="">メニューを<br />お選びください</div>
        </div>
        <div className="div_btn" onClick={() => next()}>
          <div className="mb-2">
            <img src="https://sooowda.com/uber_admin/lpapp/shared/img/common/items/2024/02/bag.png" className="ItemImage" alt="Item" />
          </div>
          <div className="">
            <div className="h6">
              【初回限定】無料専用バッグ
            </div>
            <div className="text-right h6">
              ¥0<small>(税込)</small>
            </div>
            <div className="mt-2 h6 text-danger">
              ※数量限定ですので、０円サービス終了前にお受け取りくださいませ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
