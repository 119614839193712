// import { useNavigate } from "react-router-dom";
import { Tabbar } from "../compornents/Tabbar";

export const Option = () => {
  // const navigate = useNavigate();
  // 商品選択
  // const selectItem = (item_id) => {
  //   console.log(item_id);
  //   navigate("/option");
  // };
  return (
    <div className="Option">
      <div className="">
      </div>
      <div className="">
        <div className="">
          ＜
        </div>
        <div className="">
          オプションの追加
        </div>
      </div>
      <div className="">
        <div className="">
          <div className="">
            おすすめ加工
          </div>
          <div className="">
            汗抜き加工/撥水加工/毛玉取り/カビ取り/ 防虫加工 / Wクリーニング / デラックス仕上げ / プレミアム仕上げなど
          </div>
        </div>
      </div>

      <Tabbar menu="home"/>
    </div>
  );
}
