
import { Helmet } from 'react-helmet';

export function Analytics({ title }) {

  return (
    <div>
      <Helmet>
        <title>LAUNDRY PACK Now | {title}</title>
      </Helmet>
      { process.env.REACT_APP_IS_DEV !== "true" && (
        <Helmet>
          <script async　src='https://www.googletagmanager.com/gtag/js?id=G-L6YF556NWB'></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-L6YF556NWB');
          `}
          </script>
          {/* Meta Pixel Code */}
          <script>
            {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '324595360154067');
            fbq('track', 'PageView');
          `}
          </script>
          {/* End Meta Pixel Code */}

          {/* LINE Tag Base Code */}
          {/* Do Not Modify */}
          <script>
            {`
          (function(g,d,o){
            g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
            var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
            var s=d.createElement('script');s.async=1;
            s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
            var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
          })(window, document);
          _lt('init', {
            customerType: 'lap',
            tagId: '17f94314-1ee7-40fb-99a4-26eb41933eb5'
          });
          _lt('send', 'pv', ['17f94314-1ee7-40fb-99a4-26eb41933eb5']);
        `}
          </script>
          {/* End LINE Tag Base Code */}
        </Helmet>
      )}
    </div>
  );
}

export function Gtag( event,data = {} ) {
  if (process.env.REACT_APP_IS_DEV !== 'true') {
    if (window.gtag) {
      if (data.length === undefined) {
        window.gtag('event', event);
      }else{
        window.gtag('event', event, data);
      }
    }
  }
  return null;
}

export function FacebookPixel(event, data = {}) {
  if (process.env.REACT_APP_IS_DEV !== 'true') {
    if (typeof fbq !== 'undefined') {
      const script = document.createElement('script');
      // console.log(data.length);
      if(data.length === undefined){
        script.innerHTML = `
        fbq('track', '`+ event + `');
        `;
      }else{
        script.innerHTML = `
        fbq('track', '`+ event + `', ` + JSON.stringify(data) + `);
        `;
      }
      document.body.appendChild(script);
    }
  }
  return null;
}

export function Linetag( event ) {
  if (process.env.REACT_APP_IS_DEV !== 'true') {
    if (typeof _lt !== 'undefined') {
      const script = document.createElement('script');
      script.innerHTML = `
          _lt('send', 'cv', {
            type: '` + event + `'
          },['17f94314-1ee7-40fb-99a4-26eb41933eb5']);
        `;
      document.body.appendChild(script);
    }
  }
  return null;
}
