import { useEffect } from "react";
import { PageControl, KIND } from "baseui/page-control";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { useNavigate } from 'react-router-dom';
import { Analytics, Gtag } from '../../compornents/Analytics';
import React from 'react';

export const OrderStep2 = () => {
  const navigate = useNavigate();
  const engine = new Styletron();

  const next = () => {
    navigate('/order_step/step3');
  }

  useEffect(() => {
    Gtag('view_order_step2');
  }, []);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
        <Analytics title="OrderStep2" />
        <div className="Register">
          <div className="App-step1">
            <div className="App-h1 pt-5">{"STEP.2"}</div>
            <img src="/img/step2.png" alt="step2" className="App-img" />
            <div className="App-title">Uber配達員に<br /><strong>専用バッグ</strong>を渡す</div>
            <div className="App-comment">Uber配達員が専用バッグを集荷するために<br />ご自宅に伺います。専用バッグに３点入れて、<br /><strong>しっかりと密封</strong>してお渡しください。</div>
            <div className="App-h3">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <PageControl
                  currentPage={3}
                  numPages={6}
                  kind={KIND.default}
                  aria-label="label"
                />
              </div>
              <button className="button-next-w" onClick={next}>{"次へ"}</button>
            </div>
          </div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
