import { useEffect } from "react";
// import {useStyletron} from 'baseui';
// import { FormControl } from "baseui/form-control";
import { useNavigate, Link } from 'react-router-dom';
import { Analytics,Gtag,FacebookPixel,Linetag } from '../compornents/Analytics';
// import axios from 'axios';
import { IoMdClose } from "react-icons/io";
import Modal from 'react-modal';
import React from 'react';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: '50%',
    marginRight: '-90%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(255, 255, 255)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0',
    overflowY: 'scroll',
    height: '90vh',
  },
};

export const Top = () => {
  const navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const start = () => {
    navigate("/register/step1");
  }

  const login = () => {
    navigate("/register");
  }

  useEffect(() => {
    Gtag('view_top');
    Linetag('GenerateLead');
    FacebookPixel('ViewContent');
  }, []);

  return (
    <div className="Register">
      <Analytics title="おうちに居ながらお手軽クリーニング" />
      <div className="App-step0">
        <div className="row pt-4">
          <div className="offset-2 col-4 pr-1">
            <div className="register-slide-label">
              初回3点990円
            </div>
          </div>
          <div className="col-4 pl-1">
            <div className="register-slide-label">
              初回配送料無料
            </div>
          </div>
        </div>
        <div className="App-h1 mt-3">{"自宅に居ながら\nクリーニング"}</div>
        <div><img src="./img/Direct_delivered with_Uber Eats_horiz_pill_green_en_1@2x.png" className="ud_img" alt="ud logo"/></div>
        <div className="">{"集荷からお届けまでスマホひとつ！"}</div>
        <div className="App-h3">
          <div>
            <span><Link to="/rule" className="text-white">利用規約</Link></span>と<Link to="/privacy" className="text-white">プライバシーポリシー</Link>に<br />
            同意してお進みください
          </div>
          <div className="mb-2">
            <button className="button-next" onClick={start}>{"はじめる"}</button>
          </div>
          <div className="mb-2">
            <button className="button-next" onClick={login}>{"２回目の方はこちら"}</button>
          </div>
          <div className="">
            <Link onClick={openModal} className="text-white">ランドリーパック Nowとは？</Link>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="scrolly modal rounded p-3">
            <div className="">
              <div className="text-right">
                <Link onClick={closeModal} className="text-white">
                  <IoMdClose className="h4 text-gray-dark" />
                </Link>
              </div>
            </div>
            <div className="p-1">
              <div className="mb-4">
                <div className="h4 text-center">LAUNDRY PACK Nowとは？</div>
                <div className="text-center">(ランドリーパック Now)</div>
              </div>
              <div className="mb-4">
                <img src="./img/delivery2.png" alt="DeliveryImage" className="ItemImage" />
              </div>
              <div className="mb-3">
                LAUNDRY PACK Nowは、創業30年超え、5万世帯以上の実績を誇る元祖宅配クリーニング店が運営する洋服のクリーニングサービスです。
              </div>
              <div className="mb-3">
                スマホから注文するだけで、配達パートナーがご自宅にお伺いし<strong>ご依頼品のお預かり〜お届けまで</strong>すべてを代行します。
              </div>
              <div className="mb-4">
                自宅にいながらスマホ1つで “簡単クリーニング体験” ぜひお試しください！
              </div>
              <div className="mb-1">
                <img src="./img/chirashi.png" alt="ChirashiImage" className="ItemImage" />
              </div>
              <div className="">
                <small>※990円にUber配達手数料が加算されます。</small>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
