import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Analytics } from '../compornents/Analytics';

export const Company = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Company">
        <Analytics title="運営会社" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            運営会社
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-3">
            <div className="h5 mb-2">
              社名
            </div>
            <div className="">
              株式会社トゥトゥモロウ
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              所在地
            </div>
            <div className="">
              〒8150082
            </div>
            <div className="">
              福岡県福岡市南区大楠3-28-33
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              事業内容
            </div>
            <div className="">
              宅配事業<br />
              リアル店舗運営事業<br />
              ネット事業<br />
              パートナー事業<br />
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              加盟団体
            </div>
            <div className="">
              厚生労働省認可・全国クリーニング生活衛生同業組合連合会 加盟店<br />
              福岡子育て応援企業
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
