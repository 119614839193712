import { useEffect } from "react";
import { PageControl, KIND } from "baseui/page-control";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { useNavigate } from 'react-router-dom';
import { Analytics, Gtag } from '../../compornents/Analytics';
import { Post } from '../../compornents/Post';
import React from 'react';

export const OrderStep0 = () => {
  const navigate = useNavigate();
  const engine = new Styletron();

  const next = () => {
    navigate('/order_step/step1');
  }
  useEffect(() => {
    Gtag('view_order_step0');
    // 商品情報取得
    (async () => {
      const request_data = {'type':'is_step_view','value':'1'};
      await Post('post', '/customer/set_flag.php', request_data);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={{ ...LightTheme, direction: "rtl" }}>
        <Analytics title="Step1" />
        <div className="Register">
          <div className="App-step1">
            <div className="App-h1 pt-5">{"STEP.0"}</div>
            <img src="/img/step2.png" alt="step0" className="App-img" />
            <div className="App-title">専用バッグのお受け取り<br />ありがとうございました！</div>
            <div className="App-h3">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <PageControl
                  currentPage={1}
                  numPages={6}
                  kind={KIND.default}
                  aria-label="label"
                />
              </div>
              <button className="button-next-w" onClick={next}>{"次へ"}</button>
            </div>
          </div>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
