import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Analytics } from '../compornents/Analytics';

export const Tokutei = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate("/other");
  };
  return (
    <div className="App">
      <div className="Tokutei">
        <Analytics title="特定商取引法" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            特定商取引法
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-3">
            <div className="h5 mb-2">
              販売事業者名
            </div>
            <div className="">
              株式会社トゥトゥモロウ
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              運営統括責任者
            </div>
            <div className="">
              瀬戸　豊
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              販売事業者所在地
            </div>
            <div className="">
              福岡県福岡市南区大楠3-28-33
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              販売事業者所在地
            </div>
            <div className="">
              〒815-0082<br />
              福岡県福岡市南区大楠3-28-33
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              お問い合わせ先
            </div>
            <div className="">
              info[at]laundry-pack.com([at]の部分を＠に置き換えてください）にメールにてお問い合わせください。
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              販売価格
            </div>
            <div className="">
              各サービス提供ページにて表示
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              商品代金以外に必要な費用
            </div>
            <div className="">
              消費税
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              代金の支払時期および方法
            </div>
            <div className="">
              クレジットカードの場合は、納品前にご入金してください。
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              商品の受渡時期および方法
            </div>
            <div className="">
              代金のお支払を確認後、サービス提供開始します。
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              返品の取扱条件、解約条件
            </div>
            <div className="">
              <div className="h6">お客さまのご事情により返品を行う場合</div>
              着払いにて発送させていただきます。
              送料はお客さま負担となります。

            </div>
            <div className="">
              <div className="h6">当店の事情による場合</div>
              衣類をお預かり後、メンテナンスできないと判断したお預かり品、染み抜きできないお預かり品は、施術せず返品いたします。
              ただし、染み抜き作業後、落ちなかった場合は、メンテナンス料金のみお支払いいただきます。
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              不良品の取扱条件
            </div>
            <div className="">
              商品の性質上、返品は承っておりません。
            </div>
          </div>
        </div>
        <Tabbar menu="account" />
      </div>

    </div>
  );
}
