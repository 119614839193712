import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Analytics } from '../compornents/Analytics';

export const Rule = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Rule">
        <Analytics title="利用規約" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            利用規約
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          当社アプリケーションをダウンロード、およびまたは当社サービスを利用する前に、本ユーザー条件をよくお読みください。


          <div className="h5 mb-2 mt-4">ユーザー規約</div>
          <div>
            本条件（以下「ユーザー条件」）は、当社ウェブサイト（以下「ウェブサイト」）、当社サービスおよびアプリケーション、また、当社のウェブサイト、サービス、アプリケーションを通じて提供される全ての情報、提案およびまたは業務へのアクセス、およびその利用について適用されます。
          </div>
          <div className="h5 mb-2 mt-4">LAUNDRY PACK Nowが提供するサービスとは？</div>
          <div>
            LAUNDRY PACK Nowは、LAUNDRY PACK Nowが提供するアプリケーション（以下「アプリケーション」）を、ユーザーが単一のモバイル機器（スマートフォン）やPCにダウンロードおよびインストールし、これを通じて要請する第三者メンテナンス業者、クリーニング会社（以下「メンテナンス業者」）、車両運転者の提供するクリーニングおよびメンテナンスサービスを利用する手段や情報を提供します。ユーザーの利用するLAUNDRY PACK Nowが提供する全てのサービスは、以下「サービス」と言います。

          </div>
          <div className="h5 mb-2 mt-4">LAUNDRY PACK Nowとユーザーとの間の契約はどのように締結されますか?</div>
          <div>

            アプリケーションまたはサービスを利用することにより、ユーザーは、LAUNDRY PACK Nowと契約を締結します（以下「契約」）。アプリケーションまたはサービスを利用できるようにするには、まずLAUNDRY PACK Nowに登録する必要があります。登録の際、LAUNDRY PACK Nowに携帯電話番号やクレジットカードのデータ等の個人情報を提示する必要があります。LAUNDRY PACK Nowへの登録が完了したら、LAUNDRY PACK Nowは、ユーザー設定のパスワードを使ってアクセスできる個人アカウントを提供します。<br />
            アプリケーションまたはサービスを利用できるのは18歳以上の方に限られます。法令の定める年齢制限でアプリケーションまたはサービスを利用することが制限される、もしくは年齢制限でこの契約を締結することが制限される地域にお住まいの方は、それらの年齢制限を守る必要があるため、本サービスまたはアプリケーションを利用することはできません。ユーザーが個人である場合は、拘束力のある契約を締結する法的年齢に達していることを、法人を代表して登録する場合には、法人としてLAUNDRY PACK Nowの本条件に同意して契約を締結し、サービスおよびアプリケーションの登録を行う権限を与えられていることを表明します。

          </div>
          <div className="h5 mb-2 mt-4">サービスおよびアプリケーションの利用方法</div>
          <div>

            本サービスおよびアプリケーションを利用してメンテナンス業者に集配サービスの要請を行うことができます。集配サービスの要請を受諾するか拒否するかはメンテナンス業者の自由裁量に委ねられます。メンテナンス業者が要請を受諾する場合には、アプリケーションを通じてユーザーに通知され、業者の名前、カスタマーサービスの評価点等、メンテナンス業者に関する情報や、電話でメンテナンス業者に連絡できる手段が提供されます。<br />
            LAUNDRY PACK Nowは、ユーザーが集配サービスを要請する時点で、ユーザー登録情報の位置周辺に利用可能なメンテナンス業者がいれば、ユーザーが集配サービスを利用するためメンテナンス業者と連絡が取れるよう 相応の努力を払います。
          </div>
          <div className="mt-2">
            誤解のないよう: LAUNDRY PACK Now自体は集配サービスを提供しません。LAUNDRY PACK Nowはメンテナンス業者ではありません。アプリケーションおよび、またはサービスの利用を通じて要請される集配サービスを提供するか否かはメンテナンス業者の裁量に委ねられます。LAUNDRY PACK Nowの活動は、ユーザーとメンテナンス業者間の仲介業者としてのそれに過ぎません。

          </div>
          <div className="h5 mb-2 mt-4">アプリケーションまたはサービスの利用</div>
          <div>

            ユーザーは、LAUNDRY PACK Nowに提供した情報が正確で不足のないことを保証します。LAUNDRY PACK Nowは提供された情報を検証し、理由を提示することなく何時でもサービスの提供やアプリケーションの利用を拒否することができます。<br />
            ユーザーは許可された以外の方法でサービスにアクセスすることはできません。ユーザーはモバイル機器にダウンロードしたアプリケーションが適正であることを確認することに責任を負います。ユーザーが対応していないモバイル機器を使用している場合や、間違ったバージョンのアプリケーションをモバイル機器にダウンロードした場合にもLAUNDRY PACK Nowは責任を負いません。ユーザーが、対応しない機器や不正な機器を使ってサービスまたはアプリケーションを利用している場合には、LAUNDRY PACK Nowはサービスの提供やアプリケーションの利用を終了させる権利を留保します。
            アプリケーションまたはサービスの利用に際して、ユーザーは以下の各項目に同意します。
          </div>
          <div>

            1) ユーザーは、ユーザーが個人的に使用する目的のみでサービスの利用またはアプリケーションのダウンロードを行い、これを第三者に転売しない。<br />
            2) ユーザーは、他者に自分のアカウントの使用を許可しない。<br />
            3) ユーザーは、自分のアカウントを他の個人または法人に譲渡もしくは移譲しない。<br />
            4) ユーザーは、適切な許可なく他者が権利を有するアカウントを利用しない。<br />
            5) ユーザーは、違法な素材の送信や保存等を含む非合法な目的、もしくは不正な目的でサービスまたはアプリケーションを利用しない。<br />
            6) ユーザーは、サービスまたはアプリケーションを利用して他者に迷惑、不快感、不便をかけない。<br />
            7) ユーザーは、ネットワークの適正な運営を阻害しない。<br />
            8) ユーザーは、いかなる形でもサービスまたはアプリケーションに損害を与えない。<br />
            9) ユーザーは、LAUNDRY PACK Nowの書面による許可なく、アプリケーションまたはその他LAUNDRY PACK Nowのコンテンツの複製の作成や配布をしない。<br />
            10) ユーザーは、サービスおよびアプリケーションへのアクセスを許可するアカウントのパスワードやLAUNDRY PACK Nowが提供するID情報を、秘密を守って安全に保管する。<br />
            11) ユーザーは、LAUNDRY PACK Nowが合理的に要求するID情報の証明をLAUNDRY PACK Nowに提供する。<br />
            12) ユーザーは、使用が許可されるアクセスポイントまたは3G以上のデータアカウント（アプリケーション）のみを使用する。<br />
            13) ユーザーは、（ユーザーの地域で利用可能な場合）SMS（ショートメッセージサービス）で集配サービスの要請を行う際には、標準的なメッセージ料金が発生することを了解する。<br />
            14) ユーザーは、対応しない機器や不正な機器を使ってサービスまたはアプリケーションを利用しない。<br />
            15) ユーザーは、アプリケーションまたはサービスを利用する際に所在している自国、地方、州、市の適用法を順守する。<br />
          </div>
          <div>


            ユーザーが上記の規則のいずれかに違反する場合には、LAUNDRY PACK Nowは直ちにサービスの提供およびアプリケーションの利用を終了させる権利を留保します。

          </div>
          <div className="h5 mb-2 mt-4">商品の配送等及び配送等に関する個人情報の取り扱い</div>
            1) 配送業者等の利用<br/>
            ・LAUNDRY PACK Nowは、配送の委託先(以下「配送業者等」といいます。)から、ユーザーへの集配サービスの提供をする場合がございます。その場合でもそれに関するユーザーへの通知は行いませんのでご了承ください。<br />
            ・また、配送業者等に対し当該集配サービス(集配に関するお問い合わせ対応その他のユーザーとの連絡を含みます。)の提供のために必要なユーザーの個人情報(お届け先住所、氏名、電話番号など)を提供する場合があります。<br />
            2) 配送時の写真撮影<br />
            ・玄関先等に商品を置く形で配送するお届け方法(以下「置き配」といいます。)を選択された場合、当社、メンテナンス業者、又は配送業者等は、以下の目的のため商品配送時に商品を置いた場所を撮影する場合があります。撮影した写真は各社にて一定期間厳重に管理致します。<br />
            <div className="ml-2">
              ◦置き配での注文をされた利用者に配送場所をお知らせするため<br />
              ◦配送の品質保証のため<br />
              ◦利用者からのお問い合わせ対応のため<br />
              ◦その他これらの目的に付随する目的のため<br />
          </div>
          <div className="h5 mb-2 mt-4">支払い</div>
          <div>

            アプリケーションおよびサービスの利用は無料です。LAUNDRY PACK Nowは、アプリケーションおよび/またはサービスの利用料金を導入する権利を留保します。LAUNDRY PACK Nowがそのような料金を導入する決定をした場合には、それに応じてユーザーに通知を行い、契約を継続するか解除するか選んでいただきます。<br />
            メンテナンス業者が提供する集配サービスの料金は、ウェブサイト上およびアプリケーションを通じてご覧いただけます。料金はLAUNDRY PACK Nowにより適宜変更もしくは更新されることがあります。集配サービスの料金については、ユーザーの責任で最新のデータを参照してください。<br />
            メンテナンス業者がユーザーに提供する集配サービスについての請求は、LAUNDRY PACK Nowが行うものとします。ユーザーは、メンテナンス業者により提供された全ての集配サービスの利用料金の支払いに際して、ユーザーの口座に発生する、またはこれに関連する（税および延滞料（該当する場合）を含めて）、集配サービスのサービスに登録する際にユーザーが提供するクレジットカードの口座にLAUNDRY PACK Nowから請求することに同意します。ユーザーは、全ての料金を遅延なく支払い、何時でも全ての料金支払用の有効なクレジットカードアカウント情報をLAUNDRY PACK Nowに提供することに責任を負います。支払われた料金について払い戻しはできません。<br />
            LAUNDRY PACK Nowは第三者のペイメントプロセッサー（以下「ペイメントプロセッサー」）を利用して、ユーザーのクレジットカードアカウントをアプリケーションおよびサービスにリンクさせます。アプリケーションおよびサービスの利用に関連する支払いまたはクレジット（該当する場合）の処理は、本ユーザー条件に加えて、ペイメントプロセッサーおよびユーザーのクレジットカード会社の条件や個人情報保護方針に従って行われます。LAUNDRY PACK Nowは、ペイメントプロセッサーによるいかなる過失にも責任を負いません。ユーザーのサービスの利用に関連して、LAUNDRY PACK Nowは一定の取引情報を入手します。この情報は「個人情報保護およびCookieに関する通知」に従って、LAUNDRY PACK Nowのみが使用するものです。

          </div>
          <div className="h5 mb-2 mt-4">免責</div>
          <div>

            本ユーザー条件に同意し、アプリケーションまたはサービスを利用することにより、ユーザーは、<br />
            a. 本ユーザー条件の規定、または本ユーザー条件に記載されているか否かに関わらず、適用法もしくは規制に対するユーザーの違反もしくは不履行<br />
            b. ユーザーの、アプリケーションを通じて手配したメンテナンス業者を含む第三者の権利の侵害<br />
            c. ユーザーの、アプリケーションまたはサービスの利用もしくは乱用に起因する、もしくはこれに関連するクレーム、経費、損害、損失、賠償責任、（弁護士費用を含む）費用の全てを補償し、LAUNDRY PACK Nowおよびその関連会社、ライセンサー、その役員、取締役、その他のユーザー、従業員、弁護士、代理人に損害を与えないことに同意します。<br />

          </div>
          <div className="h5 mb-2 mt-4">賠償責任</div>
          <div>

            ウェブサイト上、もしくはサービスおよびアプリケーションを通じてユーザーに提供される情報、提案、および/またはサービスは、一般的な情報提供を目的とするものであって、通知を構成するものではありません。LAUNDRY PACK Nowはウェブサイトおよびアプリケーションとそのコンテンツを誤りなく、最新の状態に維持するよう合理的に努めますが、ウェブサイト（の内容）および/またはアプリケーションに誤り、欠陥、マルウェア、ウイルスがないこと、もしくはウェブサイトおよび/またはアプリケーションに誤りがなく、最新、正確であることを保証しません。<br />
            LAUNDRY PACK Nowは、LAUNDRY PACK Now側の重大な過失もしくは故意の不正行為に起因する損害でない限り、マルウェア、ウイルス、またはウェブサイトもしくはアプリケーションの情報の不正確さや不完全さによって引き起こされる損害も含め、ウェブサイトまたはアプリケーションの利用（もしくは利用できないこと）に起因する（死亡または人身傷害を除く）いかなる損害についても責任を負いません。<br />
            LAUNDRY PACK Nowはまた、電子通信の障害もしくは遅延、第三者もしくは電子通信に使用されるコンピュータプログラムによる電子通信の遮断もしくは操作、およびウイルスの送信等に起因する損害を含め、ウェブサイトまたはアプリケーションによる電子的通信手段の利用（もしくは利用できないこと）に起因するいかなる損害についても責任を負いません。<br />
            LAUNDRY PACK Nowの過失または故意の不正行為により直接発生し、ユーザーに生じた損害については、強制力のある適用法により許可される範囲において、LAUNDRY PACK Nowの賠償責任の総額は、損害保険で対応できる範囲、または、ユーザーの通貨で集配サービスの代価としてメンテナンス業者に支払われる料金に相当する金額を上限とします。<br />
            アプリケーションまたはサービスの利用を通じて要請される集配サービスの品質については、最終的にユーザーに集配サービスを提供するメンテナンス業者の責任となります。LAUNDRY PACK Nowは、いかなる場合にも、メンテナンス業者が提供する集配サービス 、またはメンテナンス業者の側の作為、行為、行動、言動、過失に関連する、および/またはこれに起因する損害に対して責任を負いません。メンテナンス業者が提供する集配サービスに関する苦情については、メンテナンス業者に、ユーザー側から直接対応するものとします。

          </div>
          <div className="h5 mb-2 mt-4">ライセンスの供与、制限、および著作権に関する方針</div>
          <div>

            本ユーザー条件においては、以下の用語の定義が適用されます。<br />
            「コンテンツ（Content）」とは、ロゴ、アイコン、商標、テキスト、図形テキスト、グラフィックス、写真、画像、動画、音声、イラストレーション、音楽、（本アプリケーションを含む）ソフトウェア、意見、感想、論評、図柄、リンク、質問、提案、情報、またはその他の素材等を含め、掲載または表示される全ての内容物をいう。<br />
            「LAUNDRY PACK Nowコンテンツ（LAUNDRY PACK Now Content）」とは、LAUNDRY PACK Now、およびその関連会社またはライセンサーが所有し使用するコンテンツをいい、ウェブサイト、サービス、またはアプリケーションを通じて提供される。第三者からライセンス供与を受けたコンテンツを含むが、ユーザーコンテンツは含まれない。<br />
            「ユーザー（User）」とは、サービスまたはアプリケーションにアクセスする、またはこれを利用する個人をいう。<br />
            「ユーザーコンテンツ（User Content）」とは、ユーザーが投稿、アップロード、公表、提示、または伝送し、ウェブサイト上、またはサービスもしくはアプリケーションを通じて利用できるコンテンツをいう。<br />
            「総合コンテンツ（Collective Content）」とは、LAUNDRY PACK Nowコンテンツとユーザーコンテンツを合せた総称である。
            本ユーザー条件の順守を条件に、LAUNDRY PACK Nowはユーザーに制限付き、非独占的、譲渡不能のライセンスを許諾します。 許諾内容は、<br />
            i	非営利目的で、個人的な使用に限ってのLAUNDRY PACK Nowコンテンツの閲覧、ダウンロード、印刷。<br />
            ii	非営利目的で、個人的な使用に限っての、アクセスが許可されるユーザーコンテンツの閲覧。<br />
            ユーザーは、本規定で許諾されるライセンス権のサブライセンスを許諾することはできません。<br />
            ユーザーは、本ユーザー条件に明示的に許可される場合を除いては、ウェブサイト、サービス、アプリケーション、または総合コンテンツを利用、コピー、改作、修正、二次的著作物の作成、配布、ライセンス許諾、販売、譲渡、公表、公開、複製、送信、ストリーミング、放送、もしくは不法に利用することはできません。ユーザーは、あらかじめLAUNDRY PACK Nowから書面による同意を得ない限り、いかなる総合コンテンツも再利用することはできません。本ユーザー条件において明示的に許諾されるライセンスおよび権利を除き、黙示的に、またはLAUNDRY PACK Nowもしくはそのライセンサーが所有もしくは管理する知的財産権に基づき、いかなるライセンスまたは権利もユーザーに許諾されません。<br />
            ユーザーによるライセンス許諾 LAUNDRY PACK Nowは、独自の判断で、ウェブサイト上で、またはサービスもしくはアプリケーションを通じて、ユーザーコンテンツを投稿、アップロード、公表、提示、または伝送することをユーザーに許可することができます。ユーザーコンテンツは、非機密の一般情報とみなされます。従って、LAUNDRY PACK Nowは、その目的、媒体を問わず、いかなるユーザーコンテンツも世界各地で、利用、複製、配布、第三者に開示する非独占的、ロイヤリティフリーの権利を有するものとします（「ライセンス許諾」）</div>
          <div>


            ユーザーは、LAUNDRY PACK Nowはユーザーコンテンツを配布する受動的パイプ役として機能するに過ぎず、その内容もしくはユーザーコンテンツの正確性について責任を負わないこと、またはユーザーもしくはいかなる第三者にも賠償義務を負わないことを認めます。 LAUNDRY PACK Nowは、ユーザーが公表する、またはユーザー間で管理されるユーザーコンテンツを継続的に監視することをせず、そうする義務も負わないものとします。上記を制限することなく、ユーザーは、ユーザーコンテンツに表示または記載されるいかなる所見、意見、論評、提案、その他の情報もLAUNDRY PACK Nowのそれを表すものとは限らないことを認め、これに同意します。<br />
            ユーザーコンテンツについては、いかなる場合もユーザーの責任で利用するものとします。ユーザーは、ユーザーが投稿または伝送するユーザーコンテンツがユーザーのオリジナルであり、いかなる第三者の作品も複製しないこと、いかなる第三者の知的財産権、プライバシー権、人格権も侵害しないこと、また、いかなる中傷的もしくは侮蔑的内容も含まないことを表明し保証します。さらに、ユーザーは、本項に規定のライセンスを許諾する資格を有することを表明し保証します。<br />
            ユーザーは、ユーザーが投稿または伝送するユーザーコンテンツ、もしくはその他ユーザーのウェブサイト、サービス、アプリケーションの使用に関連してLAUNDRY PACK Nowまたはその関連会社が被る全ての費用、経費、損害、損失、賠償責任について補償し、LAUNDRY PACK Now、およびその関連会社、ライセンサーに何らの損害も与えないことに同意します。<br />
            LAUNDRY PACK Nowは、ユーザーが投稿もしくは伝送し、本ユーザー条件に適合しないとLAUNDRY PACK Nowが判断する、もしくはLAUNDRY PACK Nowでは容認できない、（第三者の知的財産権、プライバシー権、人格権を侵害する、もしくは侵害する可能性のある素材を含む）ユーザーコンテンツ（の全体または一部）を、独自の判断で遮断もしくは削除する権利を留保します。<br />
            ユーザーは、本ユーザー条件に抵触するいかなるユーザーコンテンツについても、直ちに書面でLAUNDRY PACK Nowに通知することに同意します。ユーザーは、当該ユーザーコンテンツが本ユーザー条件に抵触するかどうかS LAUNDRY PACK Nowで調査を行えるよう、十分な情報をLAUNDRY PACK Nowに提供することに同意します。LAUNDRY PACK Nowは、そのような苦情の調査に誠心誠意取り組み、 LAUNDRY PACK Nowの独自の判断による措置を講じることに同意します。しかし、LAUNDRY PACK Nowは、当該ユーザーコンテンツ（の全体もしくは一部）を遮断もしくは削除することを保証または表明しません。

          </div>
          <div className="h5 mb-2 mt-4">アプリケーションのライセンス</div>
          <div>
            ユーザーが本ユーザー条件に従うことを条件に、LAUNDRY PACK Nowはユーザーに、ユーザーが所有または管理する単一のモバイル機器に本アプリケーションの複製をダウンロードおよびインストールし、ユーザーの個人的な使用のみを目的として当該アプリケーションの複製を実行する制限付き、非独占的、譲渡不能のライセンスを許諾します。<br />
            ユーザーは、<br />
            1) いかなる方法によっても、サービスまたはアプリケーションをライセンス許諾、サブライセンス、販売、転売、譲渡、移譲、配布、営利目的で利用、または第三者に提供してはなりません。<br />
            2) サービスまたはアプリケーションの修正、もしくはこれに基づいて二次的著作物を作成してはなりません。<br />
            3) サービスへの「リンク」の作成、または他のサーバー、ワイヤレスもしくはインターネットベースの機器にアプリケーションの「フレーム」の作成、もしくは「ミラーリング」をしてはなりません。<br />
            4) リバースエンジニアリング、または、<br />
            a. 競合製品またはサービスを作成もしくは構築するため、<br />
            b. サービスまたはアプリケーションと類似のアイデア、特長、機能、グラフィックスを使用して製品を作成もしくは構築するため、<br />
            c. サービスまたはアプリケーションのアイデア、特長、機能、グラフィックスを複製するために、アプリケーションにアクセスしてはなりません。<br />
            5) Webスパイダー、Webクローラー、Webロボット、Webant、Webインデクサー、ボット、ウイルス、ワーム、または1秒間に複数のサーバーリクエストを行うプログラム、サービスまたはアプリケーションの運用および/または実行を不正に妨げるプログラム等を含む、自動プログラムまたはスクリプトを立ち上げてはなりません。<br />
            ユーザーは、<br />
            a. 適用法に違反して、スパムまたは重複した迷惑メールを送信してはなりません。<br />
            b. 子供に有害な素材、または第三者のプライバシー権を侵害する素材を含め、著作権を侵害する、猥褻、脅迫的、中傷的、もしくは違法、不法な素材を送信もしくは保存してはなりません。<br />
            c. ソフトウェアウイルス、ワーム、「トロイの木馬」、その他の有害なコンピュータコード、ファイル、スクリプト、エージェント、もしくはプログラムを含む素材を送信もしくは保存してはなりません。<br />
            d. ウェブサイト、アプリケーション、サービス、もしくはそれに盛り込まれたデータの整合性または実行に干渉もしくは妨害してはなりません。<br />
            e. ウェブサイト、アプリケーション、サービス、またはその関連システムもしくはネットワークに不正にアクセスを試みてはなりません。<br />
          </div>
          <div>


            LAUNDRY PACK Nowは、上記のいずれの違反についても調査を行い、法を最大限に適用して起訴する権利を有します。 LAUNDRY PACK Nowは、法執行当局と連携して本ユーザー条件に違反するユーザーの訴追に当たることができます。ユーザーは、LAUNDRY PACK Nowにはユーザーのウェブサイト、サービス、アプリケーション、総合コンテンツへのアクセスもしくはこれの利用を監視する義務、またはいかなる総合コンテンツも再検討もしくは編集する義務はないけれども、ウェブサイト、アプリケーション、サービスを運営するに当たって、ユーザーが本ユーザー条件を順守していることを確認し、適用法または裁判所、行政機関、もしくはその他の政府機関の指令もしくは要件を満たすため、これを行う権利を有することを認めます。<br />LAUNDRY PACK Nowは、LAUNDRY PACK Nowが独自の判断で本ユーザー条件に抵触するとみなす、もしくはウェブサイト、サービス、アプリケーションに有害とみなすいかなる総合コンテンツについても、何時でも事前通知なく削除する、もしくはこれへのアクセスを遮断する権利を留保します。

          </div>
          <div className="h5 mb-2 mt-4">著作権に関する方針</div>
          <div>

            LAUNDRY PACK Nowは著作権法を順守し、ユーザーにも同様の順守を求めます。著作権保有者の権利を（繰り返し）侵害、もしくは（繰り返し）侵害していると考えられるユーザーまたはアカウントホルダーの契約は、適切な状況で解除するのがLAUNDRY PACK Nowの方針です。

          </div>
          <div className="h5 mb-2 mt-4">知的財産の所有権</div>
          <div>

            LAUNDRY PACK Now（および（場合により）そのライセンサー）は単独で、ウェブサイト、アプリケーション、およびサービスに関連する全ての知的財産権、提案、アイデア、改善要求、フィードバック、提言、その他ユーザーもしくはウェブサイト、アプリケーション、サービスに関連するその他の当事者が提供する情報を含め、全ての権利、所有権、権益を保有します。<br />
            本ユーザー条件は販売を構成するものではなく、ウェブサイト、アプリケーション、サービスに関連するいかなる所有権、もしくはLAUNDRY PACK Nowが所有する知的財産権もユーザーに譲渡されません。LAUNDRY PACK Nowの名称、ロゴ、アプリケーションおよびサービスに関連する製品名は、LAUNDRY PACK Now、その関連会社もしくは第三者の商標であり、これを使用するいかなる権利またはライセンスも許諾されません。

          </div>
          <div className="h5 mb-2 mt-4">第三者との関係</div>
          <div>

            ウェブサイト、アプリケーション、およびサービスを利用する際、第三者と通信を開始する、第三者から商品やサービスを購入する、第三者の宣伝活動に関与する等の目的で、第三者が所有・管理するウェブサイトへのリンクが適宜提供されることがあります。こうしたリンクからの、本ウェブサイト、アプリケーション、およびサービスの外部での活動については、LAUNDRY PACK Nowの管理は及びません。<br />
            ウェブサイト、アプリケーション、およびサービスを利用する際、ユーザーは、本ウェブサイト、アプリケーション、およびサービス上のリンクを通じて商品および/またはサービスを提示する第三者のサービスプロバイダー、広告主、もしくはスポンサーと通信を開始する、商品および/またはサービスを購入する、もしくは宣伝活動に関与することができます。こうしたリンクからの本ウェブサイト、アプリケーション、およびサービスの外部での活動については、LAUNDRY PACK Nowの管理は及びません。ユーザーがリンクからアクセスするウェブサイトには、それぞれ独自の条件および個人情報保護に関する方針があります。LAUNDRY PACK Nowはこうしたウェブサイトのコンテンツや活動については責任を負わず、賠償責任を問われる可能性もありませんので、こうしたウェブサイトへのアクセスは、ユーザーの責任で行ってください。<br />
            こうした外部ウェブサイトからは、ウェブサイト独自のCookieがユーザーに送られて来て、データを収集したり、個人情報の提供を要求することがありますので、利用する際にはあらかじめ、こうしたウェブサイトの利用条件や個人情報保護に関する方針を確認しておくことをお勧めします。

          </div>
          <div className="h5 mb-2 mt-4">契約の期間および解除</div>
          <div>

            LAUNDRY PACK Nowとユーザーとの間の契約は、期限を設けずに締結されます。<br />
            ユーザーは、ユーザーのスマートフォンにインストールされたアプリケーションを完全に削除して、アプリケーションおよびサービスを利用できなくすることにより、何時でも本契約を解除することができます。ユーザーは、LAUNDRY PACK Nowのウェブサイトに掲載されている指示に従って、何時でもユーザーアカウントを削除することができます。<br />
            LAUNDRY PACK Nowは、ユーザーが、<br />
            a. 本ユーザー条件のいずれかの規定に違反している場合、<br />
            b. アプリケーションまたはサービスを不正に利用しているとLAUNDRY PACK Nowが判断した場合には、（ユーザーがアプリケーションおよびサービスを利用できなくすることにより）何時でも直ちに本契約を解除することができます。LAUNDRY PACK Nowは、契約の解除について事前に通知を行う義務は負いません。解除後、本ユーザー条件に従って、これに関する通知を行います。<br />

          </div>
          <div className="h5 mb-2 mt-4">一部条項の無効</div>
          <div>

            本ユーザー条件のいずれかの規定が無効となった場合にも、本ユーザー条件のその他の規定の有効性には影響を及ぼさないものとします。<br />
            本ユーザー条件のいずれかの規定が無効となった場合、または妥当性および公平性の基準に照らして現況では容認できない場合には、その規定の代わりに、あらゆる状況を考えて容認しうる規定で、できる限り元の規定と調和する規定が、本ユーザー条件の内容と目的を考慮して両当事者間に適用されます。

          </div>
          <div className="h5 mb-2 mt-4">サービスおよびユーザー条件の修正</div>
          <div>

            LAUNDRY PACK Nowは、ウェブサイト上に通知を掲載することにより、またはサービス、アプリケーション、Eメールを通じて通知を行うことにより、何時でも、独自の判断で、本ユーザー条件のいずれをも修正または差し替える権利、または（機能、データベース、コンテンツの利用可能性等を含む）サービスもしくはアプリケーションを変更、停止、もしくは中止する権利を留保します。 LAUNDRY PACK Nowは、予告なく、賠償責任も負わず、特定の機能およびサービスに制限を加えること、またはサービスの一部もしくは全部へのアクセスを制限することもできます。

          </div>
          <div className="h5 mb-2 mt-4">通知</div>
          <div>

            LAUNDRY PACK Nowは、サービスまたはアプリケーションの総則を通じて、またはLAUNDRY PACK Nowのアカウント情報の記録に記載されたユーザーのEメールアドレスに電子メールを送信することにより、またはLAUNDRY PACK Nowのアカウント情報の記録に記載されたユーザーの住所に普通郵便で書簡を送付することにより、通知を行うことができます。

          </div>
          <div className="h5 mb-2 mt-4">譲渡</div>
          <div>

            ユーザーは、あらかじめLAUNDRY PACK Nowから書面による承認を得ない限り、本ユーザー条件に基づくユーザーの権利を譲渡することはできません。

          </div>
          <div className="h5 mb-2 mt-4">個人情報保護およびCookieに関する通知</div>
          <div>

            LAUNDRY PACK Nowは、「個人情報保護およびCookieに関する通知」に従って、ウェブサイトの閲覧者、およびアプリケーションのユーザーの個人データを収集・処理します。

          </div>
          <div className="h5 mb-2 mt-4">準拠法および紛争の解決</div>
          <div>

            本ユーザー条件は、日本の法律を準拠法とします。本ユーザー条件、もしくは本ユーザー条件の違反、解除、履行、解釈、有効性、またはウェブサイト、サービス、アプリケーションの利用から、もしくはこれに関連して生起する紛争、クレーム、もしくは論争（総称して「紛争」）については、LAUNDRY PACK Nowが本条件の規定に従ってその権利を行使し、福岡地方裁判所で解決するものとします。


          </div>
        </div>
      </div>

    </div>
  );
}
