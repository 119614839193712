
import { Analytics } from '../compornents/Analytics';

export const Notfound = () => {
  return (
    <div className="App">
      <div className="Notfound">
        <Analytics title="Notfound" />
        <h1>
          Notfound
        </h1>
      </div>
    </div>
  );
}
