import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { Analytics } from '../compornents/Analytics';

export const Leave = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate("/other");
  };
  // 退会
  async function leave() {
    console.log('order');
    // 見積もり取得
    let reason = '';
    for (let i = 1; i < 7; i++) {
      if (document.getElementById('chk' + i).checked){
        reason += document.getElementById('chk' + i).value + '\n';
      }

      console.log(reason);
    }
    const request_data = { reason: reason };
    const response_data = await Post('post', '/common/leave.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      // ログアウトも行う
      await Post('logout', '', {});
      navigate("/");
    } else {
      // エラー

    }
  };
  return (
    <div className="App">
      <div className="Leave">
        <Analytics title="退会" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            退会
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-3">
            <div className="">
              お客様によりご満足いただくためにサービス向上を目指しております。<br />
              退会の理由などご意見を是非お聞かせください。
            </div>
          </div>
        </div>
        <div className="m-4">
          <div className="mb-5">
            <div className="mb-2">
              <input type="checkbox" id="chk1" value="引っ越した" />
              <label htmlFor="chk1">引っ越した</label>
            </div>
            <div className="mb-2">
              <input type="checkbox" id="chk2" value="頻繁に利用しない" />
              <label htmlFor="chk2">頻繁に利用しない</label>
            </div>
            <div className="mb-2">
              <input type="checkbox" id="chk3" value="価格が高い" />
              <label htmlFor="chk3">価格が高い</label>
            </div>
            <div className="mb-2">
              <input type="checkbox" id="chk4" value="利用しにくい" />
              <label htmlFor="chk4">利用しにくい</label>
            </div>
            <div className="mb-2">
              <input type="checkbox" id="chk5" value="クリーニングの品質" />
              <label htmlFor="chk5">クリーニングの品質</label>
            </div>
            <div className="mb-2">
              <input type="checkbox" id="chk6" value="その他" />
              <label htmlFor="chk6">その他</label>
            </div>
          </div>
          <div className="mb-3">
            <button className="btn btn-oval btn-outline-secondary form-control" onClick={leave}>退会する</button>
          </div>
        </div>
        <Tabbar menu="account" />
      </div>
    </div>
  );
}
