import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Analytics } from '../compornents/Analytics';

export const Baisyo = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Baisyo">
        <Analytics title="賠償基準" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            賠償基準
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="h5 mb-2 mt-4">第１条(利用者登録)</div>
          <div>当サービスをご利用頂くお客様は、もれなく依頼品の情報管理をWeb上で提供する利用者登録させていただきます。</div>
          <div className="h5 mb-2 mt-4">第２条(利用資格)</div>
          <div>常時なんらかの方法（電話・携帯・メール等）で双方向の連絡が取れる方。 本約款に則ってご利用頂ける方とさせていただきます。</div>
          <div className="h5 mb-2 mt-4">第３条(利用方法とお断り)</div>
          <div>サービスご利用前に、ポケットの中の点検をお忘れなくお願いします。受付後、検品でお忘れ物が見つかった場合は有用性があると判断したものをお返しします。当方判断による廃棄、返品無用品に起因する責には応じられません。
            ホコロビ、キズ、小さな穴など、加工中・加工後に変化することがありますので、よく点検してからお出しください。こちらの検品で見つかった場合は工程途中でお返しする場合がございます。お急ぎ品ほどよく検品してお出しください。</div>
          <div>上下セットなどの対でのご依頼品は必ず一緒にお出し下さい。どんなに染色がしっかりした商品でも、クリーニングなどの加工回数によって色合いが変化してきます。</div>
          <div>また長い反物からなる染色工程時点で、染色堅牢度の誤差から色見に差異が生じてくる商品もございます。</div>
          <div>シミの種類や付けてしまった日等、なるべく詳細にお申し出ください。早めにお出しいただければ、修復可能性は高くなります。逆に落ちやすいシミでも日数が経つと酸化して落ちづらくなります。また、シミの種類によっては数ヶ月着用後又は保管後、クリーニング工程中に浮き出てくるものや変色するものもございます。
            お客様のご都合によって、発送準備後に、ご依頼品を発送できない場合、ご依頼品は預託扱いとなります。その場合、発送準備後より１点に付１ヶ月５５０円の保管料・火災保険料を申し受けます。保管料をご納付いただけない場合の商品のお返しは出来かねます。
            当方からの連絡の有無に関わらず、発送準備後より３ヶ月を経過してもお引取りがない場合、以降そのご依頼品の毀損、汚損、減失、又は盗難火事等によって生ずる損害についての責任は負いかねます。 また3ヵ月を過ぎますと、破棄処分の対象となりますので、十分ご注意ください。
            マスコミ等で報道されているとおり、家庭洗濯トラブルが全国で急激なペースで頻発しています。難洗衣料等は特にプロのクリーニングをおすすめします。ドライマークが洗えると謳う洗剤・洗濯機を使っても家庭で水以外の溶剤と指定されているドライクリーニングは行えません。一度縮んだり、色が抜けたり、風合いが変わったお召し物は私達プロでも修正不可能なものも多く、これら事故後商品や他店で不適切なクリーニングをされ事故品となったものを当方に預けてからおきるお申し出には対応いたしかねます。
            飾りボタン類や装飾品は外してからご依頼ください。これらは洗えないものが数多くあります。衣類についている品質表示に洗えるとなっていてもこれらのものに対しての表示でないものも多くなっております。よってこれらの紛失、欠損は補償外となります。
            商品お受取時には、受領点数確認を必ず行ってください。お渡し後の点数相違のお申し出はお受けできません。
            商品についている番号本タグは必ず検品した後おはずしください。お客様の商品は全て番号本タグで管理しております。番号本タグ取り外し後や取扱の不正が見受けられた場合のお問い合わせはお受けできません。尚、その他ハンガーや包装材等についている補助タグにはこれら効力はございません。
            お電話等で大声をあげたり、恐怖感を与えたと取られる行為が見受けられた場合については暴力団関係者の判定を得ることなく当方判断で110番通報、もしくは担当機関に相談させていただく場合がございます。※暴力団員による不当な行為の防止等に関する法律第14条第2項に規定する不当要求防止責任者証 第７５８号
            ご依頼品も経年劣化及び変化、耐用年数、取扱方法がございます。お渡し後の個人の感性や感覚部分等に関するお申し出があった場合、弊社品質規定に則った再洗い・再仕上げを１度に限り、補償期間内であれば無料でお受けさせて頂きます。但し番号本タグ付商品に限らせていただきます。また、弊社品質規定を大きく超えるご要望に関しましては、対応いたしかねますので、ご了承ください。
          </div>
          <div className="h5 mb-2 mt-4">第４条(賠償制度)</div>
          <div>補償期間は、お届け日より２週間です。
            大切なお召し物、購入時価格一点１１万円を越す商品は、デラックスコースもしくは、プレミアコースをご指定ください。
            万一弊社に過失があった場合、当社規定事故賠償基準に基づき対応させていただきます。なお、 責任者判定を行うために、繊維製品における専門機関の鑑定もしくは繊維製品品質管理士の鑑定等を利用した場合、責任の所在が使用者もしくは製造者（メーカー）などと判明した場合、その過失割合に応じた鑑定料を実費ご請求させていただきます。
            責任所在の断定をすることが難しい場合においては、弊社では問題解決に直結する形を目指す理由から、着用に耐えうる状態での商品の納品を最優先しております。</div>
          <div className="h5 mb-2 mt-4">第５条(事故原因所在)</div>
          <div>事故原因所在を以下の三つに大別します。</div>
          <div>加工業社の作業方法に過失がある場合</div>
          <div>製造者（アパレルメーカー）側の企画・製造に過失がある場合</div>
          <div>お客さまの着用(使用）時及び保管時に過失がある場合</div>
          <div>事故賠償制度（クリーニング事故賠償）が適用されるのは(1)のクリーニング方法に過失があるとみなされた場合に限ります。</div>
          <div>なお、クリーニング事 故原因の決定は繊維製品検査所等の鑑定に基づくものとします。</div>
          <div className="h5 mb-2 mt-4">第６条(賠償範囲)</div>
          <div>弊社が事故賠償の責に応じられるのは次に示す第５条(1)の内容です。</div>
          <div>クリーニング洗浄による損傷</div>
          <div>シミ抜き工程による損傷</div>
          <div>プレス仕上げによる損傷</div>
          <div>不明及び紛失</div>
          <div>その他の原因による損傷につきましては、繊維製品における専門機関の所見もしくは繊維製品品質管理士の所見による判断に基づくものとします。</div>
          <div className="h5 mb-2 mt-4">第７条(賠償対象外)</div>
          <div>次に示す第５条（２）（３）の原因所在に関しましては賠償の責に応じかねます。
            製造者（アパレルメーカー）の企画・製造等に過失がある場合
            経年劣化及び変化の著しい素材で企画・製造された商品（ポリウレタン加工等）
            染色堅牢度の弱い素材で企画・製造された商品
            接着方法に問題のある素材、接着剤で組み合わされ企画・製造された商品
            熱セット性が弱い生地で企画・製造された商品（プリーツ加工やシワ加工等）
            クリーニング方法がまったく異なる素材で組み合わされ企画・製造された商品
            組成表示や洗濯表示に誤記が見受けられる商品
            表示責任者の名称と連絡先の表示がない商品
            通常の使用に耐えない素材で企画・製造された商品
            通常のクリーニングに耐えない素材で企画・製造された商品（洗濯表示が全て不可表記商品・スパンコール・刺繍・ビーズ・プリント剥離・装飾品の破損・ボタン等の欠落及び破損を含む）
            縫製撚糸の弱い商品によるほつれやほころび
            その他企画・製造等に起因する事項
            使用者の使用方法及び保管方法等に過失がある場合
            化学薬品等による変退色や脱色が見受けられる商品（整髪剤・パーマ液・洗剤・漂白剤・バッテリー液・排気ガス等の付着によるもの）
            汗や日光・照明による変退色や脱色
            着用時に発生した破れ・ほつれ・糸引き等
            ボタンの欠落及び破損
            使用者保管中の損傷
            経年劣化及び変化によるもの
            組成表示・洗濯表示・表示責任者タグ（メーカータグ）のいずれかが欠落した商品
            その他これらに類する使用者による事故</div>

          <div className="h5 mb-2 mt-4">第８条(賠償条件)</div>
          <div>第６条に基づく賠償条件としては以下の通りです。
            当該商品お届け後２週間以内に判明したものとします。もしくは、当社が事故扱いと認めた場合に限ります。（２週間以上経過したものは瑕疵部分も含めて事故賠償制度の対象外となりますのであらかじめご注意ください。）
            補償時必要となります商品購入価格については、購入時の領収書・レシートを必要とします。それ等が紛失、または手元にない場合につきましては、商品製造年月日を基準としたメーカー調査を行い、当時の参考価格を元に購入価格を決定させていただきます。メーカーと連絡が取れない、又は商品の確認が取れない場合につきましては都度協議の上、決定させていただきます。
            時価を超えての補償、商品への付加価値には応じられません。
            当該損害弁償品の返却は、第5条（1）の場合はお返しできません。賠償することにより、弁償品は弊社引き取りとなります。ただし、第１１条 事故賠償基準の４の場合は除きます。また、ご利用代金は返却いたします（※第５条（２）（３）の場合のご利用代金の返却は出来ません。）
            購入価格一点１１万円を越す商品の補償は、事故賠償基準に基づき対応させて頂きますが、商品購入価格を最高５万５千円を限度として算出させていただきます。
            メーカーが製造物責任（製品の欠陥により消費者が生命・身体・財産に損害を被った場合、製造者などに賠償責任を負わせること。PL法）に任ずるように、お客様に代わって事故賠償交渉を弊社が行う場合もございます。
            お客さまの着用時（使用時）に原因があると判断された事故については事故賠償制度の対象になりませんのでご注意ください。
            メールでの問い合わせのち、お預かりした衣類は事故賠償制度に準じて同意されたものとします。
            外国で購入された商品、偽造品、並行輸入品につきましては、いかなる事故におきましても補償対象外とさせていただきます。</div>
          <div className="h5 mb-2 mt-4">第９条(免責)</div>
          <div>台風、地震などの自然災害による事故。
            運送時に発生した事故。
            主観的価値である無形的損害賠償や精神的慰謝料には応じられません。
            当社側に故意の重過失があった場合には民法の規定によります。
            インポート商品などの衣文化の違いによる事故についての賠償も時価の範囲を超えることはありません。
            事故賠償制度は、全損（本体が使用に耐えられないと判断）したときのみ適用されます。（※サービス結果について、満足が得られない場合についての保証制度ではありません。）</div>
          <div className="h5 mb-2 mt-4">第１０条(約款内容の変更)</div>
          <div>当約款は、事前の通知をすることなく、本約款の内容または名称を変更することがございます。この場合の利用条件は、商品お預かり時点の約款によります。約款内容変更にあたって、その旨を広く周知する努力をするものとします。</div>
          <div className="h5 mb-2 mt-4">第１１条(協議事項)</div>
          <div>本約款・事故賠償基準に記載無き事項及び本約款の条項の解釈につき疑義を生じた事項については、お客様と弊社担当員において相互信頼の精神に基づき、協議の上、穏やかに解決を計るものとさせていただきますが、二者間において問題解決が難しいと判断させていただいた場合には、中立公正な第三者機関にお客様にも仲裁申し出をお願いする場合がございます。事故賠償基準
            万一弊社に過失があった場合、補償は下記に規定する事故賠償基準に基づき対応させていただきます。
            賠償額は、特約のあった場合のほかは次の方式によって算定いたします。
            「商品別平均使用年数」及び「物品の購入から経過月数に対応する補償割合」の算定は、全国クリーニング生活衛生同業組合連合会規定「クリーニング事故賠償基準」に準拠した当社賠償基準に基づきます。
            当店が賠償金と同時に事故品を引き渡す場合は、賠償額の50％とさせていただきます。</div>
          <div className="h5 mb-2 mt-4">第１２条(お取引のお断り)</div>
          <div>当社は、お客様が次の各号の一に該当する場合、何らの催告も要することなく、お客様との契約を解除させていただきます。また、お客様の当社の店舗及び敷地（駐車場等一切の付属施設を含みます。以下「店舗及び敷地等」といいます。）への立入を禁止し、立入後においては直ちに退去いただき、かつ商品販売その他一切のお取引をお断りいたします。また、当方判断で110番通報、もしくは担当機関に相談させていただく場合がございます。（不当要求防止責任者証 第７５８号 取得済）
            お客様が暴力団、暴力団員、暴力団関係企業・団体またはその関係者、その他反社会勢力である場合、あるいはお取引開始後に判明した場合
            当社の店舗及び敷地等において粗野または乱暴な言動をして、他のお客様に迷惑、不安感、不快感を与える場合
            お客様が当社従業員に対し、暴力的要求行為を行ない、あるいは合理的範囲を超える負担を要求した場合</div>
          <div>改定日 : 2020.1.22 株式会社 トゥトゥモロウ</div>
        </div>
        <Tabbar menu="account" />
      </div>

    </div>
  );
}
