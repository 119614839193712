import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Analytics } from '../compornents/Analytics';

export const Cleaning = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate(-1);
  };
  return (
    <div className="App">
      <div className="Cleaning">
        <Analytics title="提携クリーニング店" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            提携クリーニング店
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-3">
            <div className="h5 mb-2">
              店名
            </div>
            <div className="">
              ラクーンデリバリー
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              所在地
            </div>
            <div className="">
              〒8150082
            </div>
            <div className="">
              福岡県福岡市南区大楠3-28-33
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              ランドリーパックNow 受付時間
            </div>
            <div className="">
              月〜土（日曜のみ定休）8:30-20:00
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              電話受付時間
            </div>
            <div className="">
              0120-466969（10：00〜19：00、水・日除く）
            </div>
          </div>
          <img src="https://sooowda.com//surpass/shared/img/company/2021072110552087_check_100dpi.jpg" className="w-100" alt="cleaning" />
          {/* <div className="mb-5">
          <div className="h5 mb-2">
            納期について
          </div>
          <div className="">
            ご利用時のお願いをご確認ください
          </div>
        </div> */}
          {/* <div className="mb-2">
          <button className="btn btn-oval btn-yellow form-control">ご利用時のお願い</button>
        </div> */}
        </div>
        <Tabbar menu="account" />
      </div>
    </div>
  );
}
