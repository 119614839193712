import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import PayjpCheckout from 'react-payjp-checkout';
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';
import { ToastContainer, toast } from 'react-toastify';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';
import { Analytics,Gtag,FacebookPixel,Linetag } from '../compornents/Analytics';
import { IoMdCheckmark } from "react-icons/io";

export const Confirm = () => {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = useState(false);
  let location = useLocation();
  const item = location.state.item;
  const quote = location.state.quote;
  console.log(quote);
  let goukei = 0;
  if (item.id === '1' || item.id === '-5') {
    goukei = Number(item.kin2);
  }else{
    goukei = Number(item.kin2) + Number(quote.fee);
  }
  const [customer, setCustomer] = useState([]);
  const position = { lat: 33.589173096297685, lng: 130.39530282258255 };
  const [isCenter, setCenter] = useState(position);
  const [isCreditError, setIsCreditError] = useState(false);
  console.log(location);

  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  const options = {
    disableDefaultUI: true,
    // デフォルトUI（衛星写真オプションなど）をキャンセルします。
    zoomControl: false,
    clickableIcons: false
  };
  // 戻る
  const back = () => {
    navigate("/home");
    // navigate("/home", { state: { is_first_order: 1 } });
  };
  // 利用規約にどうしていください
  const requestAgree = () => {
    if(isAgree===false){
      toast('利用規約に同意してください');
    }else if(customer.card_type===null){
      toast('クレジットカードを登録してください');
      setIsCreditError(true);
    }
  }
  // クレジットカード登録
  async function createCredit() {
    // openLoadingModal();
    // console.log('createCredit');
    // // 見積もり取得
    let payjp_token = document.getElementsByName('create_order_payjp_token')[0].value;
    const request_data = { payjp_token: payjp_token };
    const response_data = await Post('post', '/customer/create_credit.php', request_data);
    // console.log(payjp_token);
    if (response_data.result === 1) {
      Gtag('create_credit_success');
      FacebookPixel('AddPaymentInfo');
      customer.card_type = response_data.card_type;
      customer.expYear = response_data.expYear;
      customer.expMonth = response_data.expMonth;
      customer.last4 = response_data.last4;
      setCustomer(response_data.customer);
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('create_credit_failed', {
        error_message: response_data.error_message,
      });
    }
    // closeLoadingModal();
  };
  // 商品作成
  async function createOrder() {
    openLoadingModal();
    console.log('order');
    // 商品作成
    const request_data = { item_id: item.id, quote_id: quote.id };
    const response_data = await Post('post', '/order/create.php', request_data);
    console.log(response_data);
    if (response_data.result === 1) {
      Gtag('order_success', {
        order_id: response_data.order.id,
      });
      Linetag('Conversion');
      FacebookPixel('Purchase', { value: + response_data.order.goukei, currency: 'JPY' });
      const order = response_data.order;
      console.log(order);
      if (Number(item.id)===-5){
        // 初回注文
        navigate("/home", { state: { is_first_order: 1 } });
      } else {
        navigate("/home", { state: { is_first_order: 0 } });
      }
    } else {
      // エラー
      toast(response_data.error_message);
      Gtag('order_failed', {
        error_message: response_data.error_message,
      });
    }
    closeLoadingModal();
  };
  //

  // function reactPayjpCheckoutOnCreated() {
  // const reactPayjpCheckoutOnCreated = () => {
  //   console.log('ok');
  // }
  // function reactPayjpCheckoutOnFailed(){
  //   console.log('fail');
  // }
  const fail = () => {
    console.log('fail');
    toast('クレジットカード情報が正しくありません');
    Gtag('credit_failed');
  }

  var payjp_public_key = '';
  if (process.env.REACT_APP_IS_DEV === 'true') {
    payjp_public_key = "pk_test_88ed3508dd9a23c2c538aa38";
  } else {
    payjp_public_key = "pk_live_fa0603bf01da67561383016d";
  }
  const payjpCheckoutProps = {
    dataKey: payjp_public_key,
    dataText: '新規クレジットカード登録',
    dataPartial: 'true',
    dataSubmitText: '保存する',
    dataTokenName: 'create_order_payjp_token',
    onCreatedHandler: createCredit,
    onFailedHandler: fail,
  }

  useEffect(() => {
    // 注文情報取得
    async function getCustomer() {
      const request_data = {};
      const response_data = await Post('post', '/customer/index.php', request_data);
      console.log(response_data);
      if (response_data.result === 1) {
        setCustomer(response_data.customer);
        setCenter({ lat: Number(response_data.customer.lat), lng: Number(response_data.customer.lon) });
      } else {
        // エラー

      }
    }
    getCustomer();
  }, []);
  return (
    <div className="App">
      <div className="Confirm">
        <Analytics title="注文確認" />
        <div className="toolbar">
          <div className="toolbar_left">
            <div className="">
              <BsChevronLeft onClick={back} />
            </div>
          </div>
          <div className="toolbar_center">
            注文内容の確認
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="">
          {item.id === '-5' ? (
            <div className="">
              <div className="">
                <div className="title">
                  専用バッグお届け日時(予定)
                </div>
                <div className="content">
                  およそ<strong className="text-primary">15分〜60分</strong>でお届け可能
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="title">
                集荷日時(予定)
              </div>
              <div className="content">
                およそ<strong className="text-primary">15分〜60分</strong>で集荷可能
              </div>
            </div>
          )}
          <div className="">
            <div className="title">
              集荷先<span className="text-danger">（ピンの位置をご確認ください）</span>
            </div>
            <div className="content">
              <div className="">
                {customer.pref + customer.add1 + customer.add2 + customer.add3 + customer.room}
              </div>
              <div className="mb-3">
                <Link to="/address" state={{ quote: quote, item: item }}>編集</Link>
              </div>
              <div className="">
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  <div className="w-100 confirmMap">
                    <Map
                      center={isCenter}
                      zoom={18}
                      options={options}
                    >
                      <Marker position={isCenter} />
                    </Map>
                  </div>
                </APIProvider>
              </div>
            </div>
          </div>
          {goukei > 0 && (
          <div className="">
            <div className="title">
              クレジットカード情報
            </div>
            <div className="content">
              {customer === null ? (
                <div>
                </div>
              ) : customer.card_type === null ? (
                <>
                  <div className="mb-2">以下のカード会社に対応しております。</div>
                  <div className="mb-3">
                    <img src="./img/credit_brands.png" alt="step1" className="ItemImage" />
                  </div>
                  <div className="mb-2">
                    <PayjpCheckout {...payjpCheckoutProps} />
                    {isCreditError === true && (
                      <div className="text-danger mt-2">クレジットカードを登録してください</div>
                    )}
                  </div>
                </>
              ):(
                <div className="mr-2 ml-2">
                    <div className="row">
                      <div className="col-2">
                      </div>
                      <div className="col-5">
                        カード番号
                      </div>
                      <div className="col-5">
                        有効期限
                      </div>
                    </div>
                    <hr/>
                    <div className="row">
                      <div className="col-2 pr-0">
                        {customer.card_type==='American Express' ? (
                          <img src="./img/ic/American Express.png" alt="CreditCard" className="ItemImage" />
                        ) : customer.card_type==='Diners Club' ? (
                          <img src="./img/ic/Diners Club.png" alt="CreditCard" className="ItemImage" />
                        ) : customer.card_type === 'Discover' ? (
                          <img src="./img/ic/Discover.png" alt="CreditCard" className="ItemImage" />
                        ) : customer.card_type === 'JCB' ? (
                          <img src="./img/ic/JCB.png" alt="CreditCard" className="ItemImage" />
                        ) : customer.card_type === 'MasterCard' ? (
                          <img src="./img/ic/MasterCard.png" alt="CreditCard" className="ItemImage" />
                        ) : customer.card_type === 'Visa' && (
                          <img src="./img/ic/Visa.png" alt="CreditCard" className="ItemImage" />
                        )}
                      </div>
                      <div className="col-5 pr-0">
                        **** **** **** {customer.last4}
                      </div>
                      <div className="col-3">
                        {customer.expMonth}/{customer.expYear}
                      </div>
                      <div className="col-2 text-right">
                        <IoMdCheckmark className="text-primary text-md"/>
                      </div>
                    </div>
                </div>
              )}

            </div>
          </div>
          )}
          <div className="">
            <div className="title">
              ご注文内容の確認
            </div>
            <div className="content">
              <div className="row ml-1 mr-1 mb-2">
                <div className="col-8">
                  {item.name}
                </div>
                <div className="col-4 text-right">
                  ¥{Number(item.kin2).toLocaleString()}
                </div>
              </div>
              <div className="mb-2 border-bottom"></div>
              <div className="row ml-1 mr-1 mb-2">
                <div className="col-8">
                  <strong>小計</strong>
                </div>
                <div className="col-4 text-right">
                  ¥{Number(item.kin2).toLocaleString()}
                </div>
              </div>
              {item.id === '1' || item.id === '-5' ?
                <div>
                  <div className="row ml-1 mr-1 mb-2">
                    <div className="col-8">
                      <strong>Uber配達手数料</strong>
                    </div>
                    <div className="col-4 text-right">
                      ¥{Number(quote.fee).toLocaleString()}
                    </div>
                  </div>
                  <div className="row ml-1 mr-1 mb-2 text-primary">
                    <div className="col-8">
                      <strong>初回割引</strong>
                    </div>
                    <div className="col-4 text-right">
                      ¥-{Number(quote.fee).toLocaleString()}
                    </div>
                  </div>
                </div>
                :
                <div className="row ml-1 mr-1 mb-2">
                  <div className="col-8">
                    <strong>Uber配達手数料（通常往復分 {Number(quote.fee).toLocaleString()}円→<span className="text-danger">今なら490円</span>）</strong>
                  </div>
                  <div className="col-4 text-right">
                    ¥{Number(490).toLocaleString()}
                  </div>
                </div>
              }

              <div className="mb-2 border-bottom"></div>
              <div className="row ml-1 mr-1">
                <div className="col-6 h5">
                  合計
                </div>
                <div className="col-6 text-right h4">
                  ¥{goukei.toLocaleString()}
                </div>
              </div>
            </div>
            <div className="explain mb-3">
              <div className="mt-1 mb-3">
                配達のため、個人情報を委託先の企業に提供する場合がございます。詳細は<a href="/rule" >利用規約</a>をご確認ください。
              </div>
              <div className="mb-3">
                <div className="">
                  <input type="checkbox" id="agree" onChange={() => { setIsAgree(!isAgree) }} />
                  <label htmlFor="agree">利用規約に同意する</label>
                </div>
              </div>
              <div className="mb-3">
                {isAgree === false || (customer.card_type==null && goukei>0) ? (
                  <div>
                    <button className="btn btn-oval btn-gray-dark form-control" onClick={requestAgree}>この内容で注文する</button>
                  </div>
                ) : (
                  <div>
                    <button className="btn btn-oval btn-yellow form-control" onClick={createOrder}>この内容で注文する</button>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <button className="btn btn-oval btn-outline-secondary form-control" onClick={back}>メニューに戻る</button>
              </div>
            </div>
          </div>
        </div>
        < ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="dark"
        />
        <LoadingModal>
          <div className="modal rounded text-center pb-4 pl-4 pr-4">
            <button onClick={closeLoadingModal} className="hide">閉じる</button>
            <div >
              <TailSpin stroke="#000" />
            </div>
            <div className="">
              <div className="">
                処理中です
              </div>
            </div>
          </div>
        </LoadingModal>
        <Tabbar menu="home" />
      </div>

    </div>
  );
}
