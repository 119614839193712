import { createStore, combineReducers } from "redux";
import countReducer from './reducers/count.js';
import postsReducer from './reducers/posts.js';

const rootReducer = combineReducers({
    countReducer,
    postsReducer,
});

const store = createStore(rootReducer);

export default store;
